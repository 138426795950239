import React, { useEffect, useRef, useState } from 'react';
import { Table,Input,Button, Checkbox, DatePicker, Form, Select } from 'antd';

export interface IAltisDataGridColumns
{
  title: any,
  key: string,
  placeHolder: string,
  width?: number,
  inputType:string,
  dropdownItem?: IDropdownItem[],
  hide?: boolean,
  align?: string,
  render?: (order: any, record: any, index?: any) => React.ReactNode;
  filter?: (value: any, record: any) => React.ReactNode;
}

export interface IDropdownItem {
  id:(number | string),
  name:string
}

interface IDataGridProp {
  data : Array<any>,
  total: number,
  columns : Array<IAltisDataGridColumns>,
  onChange: (e : any) => void,
  addNewData?: (e : any) => void
}

const AltisDataGrid: React.FunctionComponent<IDataGridProp> = props => {
const [dataGridColumns , setDataGridColumns] = useState<any>([]);
const [showPagination, setShowPagination] = useState(true);
const [containerStyle, setContainerStyle] = useState({});

let dataGridInfo :any  = {
  pageSize: 10,
  page: 1,
};



useEffect(() => {
  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 760) {
      // Mobil cihazlar
      setShowPagination(false);
      setContainerStyle({
        width: '100%',
        overflowX: 'auto',
        padding: '0',
        boxSizing: 'border-box',
      });
    } else {
      // Web cihazları
      setShowPagination(true);
      setContainerStyle({
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        overflowX: 'auto',
        boxSizing: 'border-box',
      });
    }
  };

  handleResize(); // İlk çağrı
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);



useEffect(()=>{
  setDataGridColumns([])
  props.columns.map((item) => {
    if (item.hide != null && item.hide == false)
    {

    }
    else if(item.inputType === 'input')
    {
      setDataGridColumns((prev : any) => [...prev ,InputType(item)])
    }
    else if(item.inputType==='checkbox')
    {
      setDataGridColumns((prev : any) => [...prev ,CheckboxType(item)])
      //props.getChecked.current.addEventListener('click', handleClickRefButton);
    }
    else if (item.inputType === 'multiplecheckbox') {
      setDataGridColumns((prev : any) => [...prev, MultipleCheckboxType(item)])
    }
    else if(item.inputType === 'date')
    {
      setDataGridColumns((prev : any) => [...prev ,DateType(item)])
    }
    else if(item.inputType === 'operations')
    {
      setDataGridColumns((prev : any) => [...prev ,OperationsType(item)])
    }
    else if(item.inputType === 'dropdown')
    {
      setDataGridColumns((prev : any) => [...prev ,DropdownType(item)])
    }
    else if (item.inputType === 'switch') {
      setDataGridColumns((prev : any) => [...prev, SwitchType(item)])
    }
    else if (item.inputType === 'boolean') {
      setDataGridColumns((prev : any) => [...prev, BooleanType(item)])
    }
    else if (item.inputType === 'custom') {
      setDataGridColumns((prev : any) => [...prev, CustomType(item)])
    }
    else
    {
      setDataGridColumns((prev : any) => [...prev ,NoFilterType(item)])
    }
  });
},[props])

const allCheckedChange = (checkValue:boolean) =>{
  // const newData = dataGridData.map(row => ({
  //   ...row,
  //   isCheckedAltisDatagrid: checkValue
  // }));
  // setDataGridData(newData);
  // console.log(dataGridData)
}

const update = (e: any) => {
  dataGridInfo = { ...dataGridInfo, ...e };
  props.onChange(dataGridInfo);
}

const objectToNull=(e:string) =>
{
  dataGridInfo[e] = null;
  props.onChange(dataGridInfo);
}

const dropdownChange = (key:any,value : any) =>{
  dataGridInfo[key] = value;
  props.onChange(dataGridInfo);
}

const multipleCheckboxChange = (key: any, dropdownKey: any, status: boolean) => {
  if (Array.isArray(dataGridInfo[key])) {
    const index = dataGridInfo[key].indexOf(dropdownKey);
    if (index !== -1) { 
      if (!status) {
        dataGridInfo[key].splice(index, 1);
      }
    } else {
      if (status) { 
        dataGridInfo[key].push(dropdownKey);
      }
    }
  } else { 
    dataGridInfo[key] = [];
    if (status) {
      dataGridInfo[key].push(dropdownKey);
    }
  }
  props.onChange(dataGridInfo);
}

const BooleanType = (item: IAltisDataGridColumns) => {
  return {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,
    width: item.width,
    dataIndex: item.key,
    render: item.render,
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
          <Form onFinish={update}>
              <Form.Item name={item.key} initialValue={false} valuePropName="checked">
                  <Checkbox> {item.placeHolder} </Checkbox>
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                  <Button type="primary" htmlType="submit" size="small" style={{ width: 90 }}> Filter </Button>
              </Form.Item>
          </Form>
          <div style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" size="small" onClick={() => objectToNull(item.key)} style={{ width: 90 }}> Temizle </Button>
          </div>
      </div>
    ),
  }
}

const CustomType = (item: IAltisDataGridColumns) => {
  return {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,
    width: item.width,
    dataIndex: item.key,
    render: item.render,
    filterDropdown: item.filter
  }
}

const SwitchType = (item: IAltisDataGridColumns) => {
  return {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,
    width: item.width,
    dataIndex: item.key,
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
          <Form onFinish={update}>
              <Form.Item name={item.key} initialValue={false} valuePropName="checked">
                  <Checkbox> {item.placeHolder} </Checkbox>
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                  <Button type="primary" htmlType="submit" size="small" style={{ width: 90 }}> Filter </Button>
              </Form.Item>
          </Form>
          <div style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" size="small" onClick={() => objectToNull(item.key)} style={{ width: 90 }}> Temizle </Button>
          </div>
      </div>
    ),
    render: item.render
  };
}

const InputType=(item:IAltisDataGridColumns)=>{
  return {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,  // uniq id oluşturur
    width:1000,  
    dataIndex: item.key, // veriyi çeker
    filterDropdown: () => (
      <div style={{ padding: 20 }} key={item.key}>
        <Form onFinish={update}>
          <Form.Item name={item.key}>
            <Input placeholder={item.placeHolder} style={{ width: 188, marginBottom: 8, display: 'block' }} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
          
            <Button type="primary" htmlType="submit" size="small" style={{ width: 100 }}> Filter </Button>
          
          </Form.Item>
      </Form>
      </div>
    )
  }
}

const CheckboxType = (item:IAltisDataGridColumns) =>{
  return {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,       // uniq id oluşturur
    width: item.width,
    dataIndex: item.key, // veriyi çeker
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Form onFinish={update}>
          <Form.Item name={item.key} initialValue={false} valuePropName="checked">
            <Checkbox> {item.placeHolder} </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
          
            <Button type="primary" htmlType="submit" size="small" style={{ width: 90 }}> Filter </Button>
           
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" size="small" onClick={()=>objectToNull(item.key)} style={{ width: 90 }}> Temizle </Button>
          </div>
      </div>
    ),
    render: (text : any, record:any) => {
      return text == true ? <Checkbox checked={true} /> : <Checkbox checked={false} />;
    },
  };
}

const MultipleCheckboxType = (item:IAltisDataGridColumns) =>{
  return {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,       // uniq id oluşturur
    width: item.width,
    dataIndex: item.key, // veriyi çeker
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
          {
            item.dropdownItem.map(row => 
              <Checkbox defaultChecked={false} onChange={(checked)=>multipleCheckboxChange(item.key,row.id,checked.target.checked)}>{row.name}</Checkbox>
            )
          }
      </div>
    )
  };
}

const OperationsType= (item:IAltisDataGridColumns) => {
  
  return {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,       // uniq id oluşturur
    width:'10%',
    dataIndex: item.key, // veriyi çeker
    render: item.render
  };
}

const NoFilterType=(item:IAltisDataGridColumns)=>{
    return {
      align: item.align == null ? 'center' : item.align,
      title: item.title,
      key: item.key,
      width: item.width,
      dataIndex: item.key,
    }
}

const DateType = (item:IAltisDataGridColumns)=>{
  return  {
    align: item.align == null ? 'center' : item.align,
    title: item.title,
    key: item.key,       // uniq id oluşturur
    width: item.width,
    dataIndex: item.key,// veriyi çeker
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Form onFinish={update}>
          <Form.Item name={item.key+"_start"}>
            <DatePicker showTime placeholder={`Select Start Date`} style={{ width: 188, marginBottom: 8, display: 'block' }} />
          </Form.Item>
          <Form.Item name={item.key+"_finish"}>
            <DatePicker showTime placeholder={`Select End Date`} style={{ width: 188, marginBottom: 8, display: 'block' }} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" size="small" style={{ width: 90 }}> Filter </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const DropdownType=(item:IAltisDataGridColumns)=>{
    return {
      align: item.align == null ? 'center' : item.align,
      title: item.title,
      key: item.key,
      width: item.width,
      dataIndex: item.key,
      filterDropdown: () => (
        <div style={{ padding: 20 }} key={item.key}>
            <Select
              placeholder={item.placeHolder}
              allowClear
              className='rounded-select'
              defaultValue={"All"}
              style={{ borderRadius: '20px !important' }}
              onChange={(value) => dropdownChange(item.key,value)}
            >
            <Select.Option key={"All"} value={null}>All</Select.Option>
              {
                item!.dropdownItem!.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))
              }
            
            </Select>
        </div>
      )
    }
}

const handlePage = (page : any, pageSize : any) => 
{
  dataGridInfo.page = page;
  dataGridInfo.pageSize = pageSize;
  props.onChange(dataGridInfo);
}

  return (
    <>
      <Table
        style={{width: '100%',}}
        rowClassName={(record) => record.backgroundColor == null ?'white-row' : (record.backgroundColor+'-row')}
        pagination={showPagination ? {
          showSizeChanger: true,
          total: props.total,
          onChange: handlePage,
        } : false}
        columns={dataGridColumns}
        dataSource={props.data}
      />
      <style>
        {`
          .white-row {
            background-color: white;
          }

          .green-row {
            background-color: rgba(102,204,102);
            color: black;
          }

          .red-row {
            background-color: rgba(255,51,51);
            color: black;
          }

          .grey-row {
            background-color: rgba(128,128,128, 0.5); 
            color: black;
          }
        `}
      </style>
    </>
  )
}


export default AltisDataGrid;
