import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next';
import { ICampusData } from "../../../interfaces/ICampusData";
import { ILocationData } from "../../../interfaces/ILocationData";

const AddLocation: React.FC<ILocationData> = ({ savedObj,cancelModal,companyId,campusId, locationName, campusTBL }) => {
    const { t } = useTranslation();
    const [campusData, setCampusData] = useState<ICampusData[]>([]);
    const [locationData, setLocationData] = useState<ILocationData[]>([]);
    const [companyData, setCompanyData] = useState<ICompanyData[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<number>();
    const loader = new StateLoader();
    const userInfo: IAuthentication = loader.loadAuthState();
    const isCompanyIdAvailable = !!companyId;
    const isCampusIdAvailable = !!campusId;

    console.log(campusId)
    console.log(isCampusIdAvailable)

    useEffect(() => {
        fetchCompanyData();
    }, []);

    useEffect(() => {
        if (selectedCompany || userInfo.companyId) {
            fetchCampusesByCompany(selectedCompany || userInfo.companyId);
        } else {
            setCampusData([]);
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (companyId !== undefined) {
            setSelectedCompany(companyId);
        }
    }, [companyId]);

    const fetchCompanyData = async () => {
        try {
            const result = await HTTPService.GETCompanyNames();
            setCompanyData(result);
        } catch (error) {
            console.error("Şirket verilerini alırken bir hata oluştu:", error);
        }
    };

    const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
        return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
    };

    const fetchCampusesByCompany = async (companyId: number) => {
        try {
            const result = await HTTPService.GETCampusByCompany(companyId);
            setCampusData(result);
        } catch (error) {
            console.error("Kampüs verilerini alırken bir hata oluştu:", error);
            setCampusData([]);
        }
    };

    const handleCompanyChange = (value: number) => {
        setSelectedCompany(value);
    };

    const handleCancel = () => {
        const currentUrl = window.location.href;
        const addInventoryUrl = 'admin/add-inventory';
        const updateInventoryUrl = 'admin/update-inventory';
    
        if (currentUrl.includes(addInventoryUrl)) {
            cancelModal();
        } else if (currentUrl.includes(updateInventoryUrl)) {
            cancelModal();
        } else {
            window.location.replace('/admin/location-management');
        }
    };

    const LocationRegister = (values) => {
        let companyIdToUse;
        if (userInfo?.role === UserRole.AltisAdmin) {
            companyIdToUse = setSelectedCompany(values.companyId);
        } else {
            companyIdToUse = userInfo?.companyId;
        }
        const newGroup = {
            locationName: values.locationName,
            companyId: companyIdToUse || companyId,
            campusId: values.campusId || campusId,
        };

        HTTPService.POST(`/Location/registerLocation`, newGroup)
            .then((response) => {
                if (response.status === 200) {
                    const currentUrl = window.location.href;
                    const redirectUrl = 'admin/add-location';
                    if (currentUrl.includes(redirectUrl)) {
                        window.location.replace('admin/location-management');
                    }
                    else if(response.status===450){
                        toast.error(t(response.data))
                    } 
                    else {
                        savedObj(response.data)
                        cancelModal();
                    }
                } else {
                    toast.error(t(response.data));
                }
            })
            .catch((error) => {
                toast.error("Location registration failed.");
            });
    };

    return (
        <Card style={{ padding: "50px" }}>
            <h3>{t('register_location')}</h3>
            <Form onFinish={LocationRegister} layout="vertical"
              fields={[
                { name: ["selectedCompany"], value: companyId },
                { name: ["campusId"], value: campusId },
              ]}
            >
                {!isCompanyIdAvailable && userInfo?.role === UserRole.AltisAdmin ? (
                    <Form.Item
                        //name='selectedCompany'
                        label={t('Select company')}
                        rules={[{ required: true, message: t('please_select_a_company') }]}
                    >
                        <Select
                            showSearch
                            placeholder={t('company_name')}
                            style={{ width: '100%', marginBottom: '16px' }}
                            onChange={handleCompanyChange}
                            filterOption={filterOption}
                        >
                            {companyData.map((company) => (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : null}
                 {!isCampusIdAvailable && (
                    <Form.Item
                        name='campusId'
                        label={t('campus')}
                        rules={[{ required: true, message: t('select_campus') }]}
                    >
                        <Select placeholder={t('campus')} style={{ width: '100%' }}>
                            {campusData.map(row => (
                                <Select.Option key={row.campusId} value={row.campusId}>
                                    {row.campusName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                 )}
                
                <Form.Item
                    name="locationName"
                    label={t('location')}
                    rules={[{ required: true, message: t('please_input_your_location'), whitespace: true }]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        style={{ borderRadius: '4px', width: '100%' }}
                        placeholder={t('location')}
                        maxLength={50}
                    />
                </Form.Item>

                <Form.Item>
                    <Row gutter={[8, 8]} justify="end" align="middle">
                        <Col xs={12} sm={4}>
                        <Button
                            block
                            onClick={handleCancel}
                            type="primary"
                            style={{ borderRadius: 0 }}
                        >
                            {t('cancel')}
                        </Button>
                        </Col>
                        <Col xs={12} sm={4}>
                        <Button
                            block
                            htmlType="submit"
                            type="primary"
                            style={{ borderRadius: 0 }}
                        >
                            {t('save')}
                        </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default AddLocation;
