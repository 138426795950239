import { DeleteOutlined, EditOutlined, ForwardOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid, { IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IApplicationData } from "../../../interfaces/IApplicationData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; // Ekleyin
import { ITypeData } from "../../../interfaces/ITypeData";
import { ICounting } from "../../../interfaces/ICountingFilter";
import { t } from "i18next";
import i18n from "../../../i18n";


const CountingManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const loader = new StateLoader();
  const [coutingData, setCoutingData] = useState<ICounting>({});
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: t('Counting Management'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    CountingFilter();
  }, [i18n.language]);



  const columns: IAltisDataGridColumns[] = [
    {
      title: t('company_name'),
      key: 'companyName',
      placeHolder: t('company_name'),
      inputType: "input"
    },
    {
      title: t('Counting Job Name'),
      key: 'countingJobName',
      placeHolder: t('Counting Job Name'),
      inputType: "input"
    },
    {
      title:  t('campus'),
      key: 'campusName',
      placeHolder:  t('campus'),
      inputType: "input"
    },
    // {
    //   title:  t('location'),
    //   key: 'locationName',
    //   placeHolder:  t('location'),
    //   inputType: "input"
    // },
    {
      title:  t('Responsible Name'),
      key: 'firstName',
      placeHolder:  t('Responsible Name'),
      inputType: "input"
    },
    {
      title:  t('start_date'),
      key: 'startDate',
      placeHolder:  t('company_name'),
      inputType: "date",
      width: 1000
    },
    {
      title: t('finish_date'),
      key: 'finishDate',
      placeHolder: t('finish_date'),
      inputType: "date",
      width: 1000
    },
    {
      title: t('Operations'),
      key: 'operations',
      placeHolder: t('Operations'),
      inputType: "operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Space size={10}>
            <Popconfirm
              title={<div>{t('are_you_sure')} <br /></div>}
              onConfirm={() => DeleteCounting(record.countingJobId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button
                size='small'
                icon={<DeleteOutlined />}
                onClick={(e) => { e.stopPropagation(); }}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
              >
                {t('delete')}
              </Button>
            </Popconfirm>

            <Button
              size='small'
              icon={<EditOutlined />}
              onClick={() => window.location.replace('/admin/update-counting/' + record.countingJobId)}
              style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            >
              {t('update')}
            </Button>
            {/* <Button
              size='small'
              icon={<ForwardOutlined/>}
              onClick={() => CountingStart(record.countingJobId)}
            >
              {t('start')}
            </Button> */}
            <Button
              size='small'
              icon={<StarOutlined />}
              onClick={() => window.location.replace('/admin/details-counting/' + record.countingJobId)}
            >
              {t('details')}
            </Button>
          </Space>
        </div>
      ),
    }
  ];

  const CountingFilter = (e?) => {
    const body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      countingJobName: e?.countingJobName,
      companyName: e?.companyName,
      campusName: e?.campusName,
      locationName: e?.locationName,
      responsibleUserName:e?.firstName,
      startDate_Start:
        e?.startDate_Start === null || e?.startDate_Start === ''
          ? null
          : new Date(Date.parse(e?.startDate_Start)),
      startDate_Finish:
        e?.startDate_Finish === null || e?.startDate_Finish === ''
          ? null
          : new Date(Date.parse(e?.startDate_Finish)),

      finishDate_Start:
        e?.finishDate_Start === null || e?.finishDate_Start === ''
          ? null
          : new Date(Date.parse(e?.finishDate_Start)),
      finishDate_Finish:
        e?.finishDate_Finish === null || e?.finishDate_Finish === ''
          ? null
          : new Date(Date.parse(e?.finishDate_Finish)),
    };
    console.log(body);
    HTTPService.POST(`/CountingJob/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        console.log(response.data);
        setCoutingData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const CountingStart = (Id) => {
    console.log(Id)
    HTTPService.GET(`/CountingJob/CountingStart?countingJobId=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.reload();
        } else {
          toast.error(response.data);
        }
      }
    );
  };

  const DeleteCounting = (Id) => {
    HTTPService.DELETE(`/CountingJob/deleteCounting?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else {
          toast.error(t(response.data));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20, display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-counting')}
          >
            {t('Add New Counting')}
          </Button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='table-container'>
            <AltisDataGrid data={coutingData.data} total={coutingData.count} columns={columns} onChange={CountingFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CountingManagement;
