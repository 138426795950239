import { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import HTTPService from "../../../utils/makerequest";
import { HomeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { IUser } from "../../../interfaces/IUser";
import { t } from "i18next";

const UpdateCompany = () => {
  const { companyId } = useParams<any>();
  const [companyData, setCompanyData] = useState<ICompanyData>({});
  const [userData, setUserData] = useState<IUser[]>();

  useEffect(() => {
    getCompanyData(companyId);
    getUserData(companyId);
  }, [companyId]);

  const getCompanyData = (companyId: number) => {
    HTTPService.GET(`/Company/byId?companyId=${companyId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        if (res.status === 200) {
          console.log(res.data)
          setCompanyData(res.data);
        } else {
          toast.error(res.error.message)
        }
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  const getUserData = (companyId: number) => {
    HTTPService.GET(`/User/allUserName?companyId=${companyId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        if (res.status === 200) {
          console.log(res.data)
          setUserData(res.data);
        } else {
          toast.error(res.error.message)
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const updateCompany = (formData) => {
    const updatedCompany = {
      companyId: companyId,
      companyName: formData.companyName,
      companyAddress: formData.companyAddress,
      companyCity: formData.companyCity,
      responsibleUserId:formData.responsibleUserId,
    };

    HTTPService.PUT(`/Company/updateCompany`, updatedCompany)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t(res.data));
          window.location.replace('/admin/customer-management');
        }
        else if(res.status ===450){
          toast.error(t(res.data));
        }
         else {
          toast.error(t(res.data));
        }
      })
      .catch((error) => {
        toast.error("There was an error updating the company.", error);
      });
  };
  const cities = [
    'Adana',
    'Adıyaman',
    'Afyonkarahisar',
    'Ağrı',
    'Amasya',
    'Ankara',
    'Antalya',
    'Artvin',
    'Aydın',
    'Balıkesir',
    'Bilecik',
    'Bingöl',
    'Bitlis',
    'Bolu',
    'Burdur',
    'Bursa',
    'Çanakkale',
    'Çankırı',
    'Çorum',
    'Denizli',
    'Diyarbakır',
    'Edirne',
    'Elazığ',
    'Erzincan',
    'Erzurum',
    'Eskişehir',
    'Gaziantep',
    'Giresun',
    'Gümüşhane',
    'Hakkâri',
    'Hatay',
    'Isparta',
    'Mersin',
    'İstanbul',
    'İzmir',
    'Kars',
    'Kastamonu',
    'Kayseri',
    'Kırklareli',
    'Kırşehir',
    'Kocaeli',
    'Konya',
    'Kütahya',
    'Malatya',
    'Manisa',
    'Kahramanmaraş',
    'Mardin',
    'Muğla',
    'Muş',
    'Nevşehir',
    'Niğde',
    'Ordu',
    'Rize',
    'Sakarya',
    'Samsun',
    'Siirt',
    'Sinop',
    'Sivas',
    'Tekirdağ',
    'Tokat',
    'Trabzon',
    'Tunceli',
    'Şanlıurfa',
    'Uşak',
    'Van',
    'Yozgat',
    'Zonguldak',
    'Aksaray',
    'Bayburt',
    'Karaman',
    'Kırıkkale',
    'Batman',
    'Şırnak',
    'Bartın',
    'Ardahan',
    'Iğdır',
    'Yalova',
    'Karabük',
    'Kilis',
    'Osmaniye',
    'Düzce'
  ];
  const handleCancel = () => {
    window.location.replace('/admin/customer-management/');
  };
  const { Option } = Select;
  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('Update Company')}</h3>
      <Form
        layout="vertical"
        onFinish={updateCompany}

        fields={[
          {
            name: ['companyName'],
            value: companyData.companyName,
          },
          {
            name: ['companyAddress'],
            value: companyData.companyAddress,
          },
          {
            name: ['companyCity'],
            value: companyData.companyCity,
          },
          {
            name: ['firstName'],
            value: companyData?.responsibleUserTBL?.firstName,
          },
           {
             name: ['responsibleUserId'],
             value: companyData.responsibleUserId,
           },
          // {
          //   name: ['lastName'],
          //   value: companyData.responsibleUserTBL?.lastName,
          // },
          // {
          //   name: ['telephone'],
          //   value: companyData.responsibleUserTBL?.telephone,
          // },
          // {
          //   name: ['userEmail'],
          //   value: companyData.responsibleUserTBL?.userEmail,
          // },


        ]}>
        <Form.Item
          label={t("Company Name")}
          name="companyName"
          rules={[
            {
              required: true,
              message: "Please input the company name!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>



        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label={t("Address")}
              name="companyAddress"
              rules={[
                {
                  required: true,
                  message: "Please input the address!",
                },
              ]}
            >
              <Input prefix={<HomeOutlined />} style={{ borderRadius: 0 }} />

            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              //label={t('location_city')}
              name='companyCity'
              rules={[
                {
                  required: true,
                  message: 'Please select your city!',
                },
              ]}
              style={{marginTop: 30}}
            >
              <Select
                showSearch
                placeholder='Select city'
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='responsibleUserId'
          label={t('responsiblename')}
            rules={[
              {
                required: true,
                message: 'Please select a responsible user!',
              },
            ]}
          >

          <Select
            showSearch placeholder={t('responsiblename')}
            style={{ marginBottom: '20px' }}
          >
            {
              console.log(userData)
            }
            {userData?.map((user) => (
              <Select.Option key={user.userId} value={user.userId}>
                {user.firstName} {user.lastName}
              </Select.Option>
            ))}
          </Select>

          </Form.Item>
        {/* <Form.Item
          label="Responsible Firstname"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input the responsible firstname!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Lastname"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input the responsible lastname!",
            },
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Telephone"
          name="telephone"
          rules={[
            {
              required: true,
              message: "Please input the responsible telephone!",
            },
          ]}
        >
          <Input prefix={<PhoneOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          label="Responsible Email"
          name="userEmail"
          rules={[
            {
              required: true,
              message: "Please input the responsible email!",
            },
            {
              type: "email",
              message: "Please input a valid email address!",
            },
          ]}
        >
          <Input prefix={<MailOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item> */}
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default UpdateCompany;
