import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { t } from "i18next";
import { IInventoryStatusData } from "../../../interfaces/IInventoryStatusData";
import { IPageProps } from "../../../interfaces/page-data";

const UpdateInventoryStatus : React.FunctionComponent<IPageProps> = () => {
  const { inventoryStatusId } = useParams<any>();
  const [inventoryStatusData, setInventoryStatusData] = useState<IInventoryStatusData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  useEffect(() => {
    fetchData();
  } , []);
  
  useEffect(() => {
    console.log(inventoryStatusId)
    if (inventoryStatusId!=null) {
      getInventoryStatusData(inventoryStatusId);
    } else if (inventoryStatusData?.inventoryStatusId) {
      getInventoryStatusData(inventoryStatusData.inventoryStatusId);
    }
  }, [inventoryStatusId]);

const fetchData = async () => {
  const result = await HTTPService.GETCompanyNames();
  console.log(result);
  setCompanyData(result);
};

  const getInventoryStatusData = (inventoryStatusId: number) => {
    HTTPService.GET(`/InventoryStatus/ById?inventoryStatusId=${inventoryStatusId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        if (res.status === 200) {
          let tmp: IInventoryStatusData = res.data;
          setInventoryStatusData(tmp);
          console.log(tmp);
        } else {
          toast.error(res.error.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching application data:", error);
      });
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  const updateInventoryStatus = (formData) => {
    const updatedInventoryStatus = {
      inventoryStatusId: inventoryStatusId,
      inventoryStatusName:formData.inventoryStatusName,
      companyId:formData.companyId || userInfo?.companyId,
    };
    console.log(updatedInventoryStatus);
    HTTPService.PUT(`/InventoryStatus/updateInventoryStatus`, updatedInventoryStatus)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('inventorystatusupdate'));
          window.location.replace("/admin/inventory-status-management");
        }else if(res.status===450){
          toast.error(t(res.data))
        } 
        else {
          toast.error(res.data);
        }
      })
      .catch((error) => {
        toast.error("There was an error updating the inventory status.", error);
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/inventory-status-management/");
  };


  return (
    <Form
      layout="vertical"
      onFinish={updateInventoryStatus}
      fields={[
        { name: ["inventoryStatusId"], value: inventoryStatusId  || inventoryStatusData?.inventoryStatusId},
        { name: ["inventoryStatusName"], value: inventoryStatusData?.inventoryStatusName },
        { name: ["companyId"], value: inventoryStatusData?.companyId },
      ]}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_inventory_status')}</h3>
        {userInfo?.role === UserRole.AltisAdmin ? (
                    <Form.Item
                        name='companyId'
                        label={t('Select company')}
                        rules={[
                            {
                                required: true,
                                message: 'Please select a company!',
                            },
                        ]}>
                          <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
                          {companyData?.map((company) => (
                              <Select.Option key={company.companyId} value={company.companyId}>
                                  {company.companyName}
                              </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                ) : null}
              <Row gutter={16}>
              <Col xs={24} sm={24} md={16} lg={24} xl={24}>
                <Form.Item
                  name="inventoryStatusName"
                  label={t('inventory_status_name')}
                  rules={[
                    {
                      required: true,
                      message: t('please_input_your_inventory_status_name'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    style={{ borderRadius: 0 }}
                    placeholder={t('inventory_status_name')}
                  />
                </Form.Item>
              </Col>
            </Row>
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
              {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
              {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateInventoryStatus;