import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next';
import { ICampusData } from "../../../interfaces/ICampusData";

const AddCampus: React.FC<ICampusData> = ({ cancelModal ,savedObj ,companyId, campusId, campusName,}) => {
  const { t } = useTranslation();
  const [companyData, setCompanyData] = useState<ICompanyData[]>([]);
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const isCompanyIdAvailable = !!companyId;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      toast.error(t('fetch_company_names_failed'));
    }
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  const CampusRegister = async (values) => {
    try {
      const companyIdToUse = userInfo?.role === UserRole.AltisAdmin
        ? values.selectedCompany
        : userInfo?.companyId || companyId;

      const newGroup = {
        campusName:values.campusName,
        companyId:companyIdToUse || companyId,
      };
      console.log(newGroup);
      console.log(companyIdToUse)
      const res = await HTTPService.POST(`/Campus/registerCampus`, newGroup);

      if (res.status === 200) {
        const currentUrl = window.location.href;
        const redirectUrl = 'admin/add-campus';
       if (currentUrl.includes(redirectUrl)) {
          window.location.replace('admin/campus-management');
        }
       else {
         savedObj(res.data)
         cancelModal();
        }
      }else if(res.status ===450){
        toast.error(t('error450'))
      }  
      else {
        toast.error(t(res.data));
      }
    } catch (error) {
      toast.error(t('add_campus_failed'));
    }
  };

  const handleCancel = () => {
    const currentUrl = window.location.href;
    const addInventoryUrl = 'admin/add-inventory';
    const updateInventoryUrl = 'admin/update-inventory';

    if (currentUrl.includes(addInventoryUrl)) {
        cancelModal();
    } else if (currentUrl.includes(updateInventoryUrl)) {
        cancelModal();
    } else {
        window.location.replace('/admin/campus-management');
    }
};

  return (
    <Card style={{ padding: "50px"}}>
      <h3>{t('register_campus')}</h3>
      <Form onFinish={CampusRegister} layout="vertical"
        fields={[
          { name: ["selectedCompany"], value: companyId },
        ]}
      >
        {!isCompanyIdAvailable && userInfo?.role === UserRole.AltisAdmin && (
          <Form.Item
            name='selectedCompany'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
              {companyData.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="campusName"
              label={t('campus')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_campus'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('campus')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: 0 }}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddCampus;
