import React, { useEffect, useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IPrinterManagement } from "../../../interfaces/IPrinterManagement";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { ICompany } from "../../../interfaces/ICompany";
import { useTranslation } from "react-i18next";

const AddPrinter: React.FunctionComponent<IPageProps> = () => {
  const [companyData, setCompanyData] = useState<ICompany[]>([]);
  const { t } = useTranslation();
  const [printerData, setPrinterData] = useState<IPrinterManagement>({})
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error('Failed to fetch company data', error);
    }
  };

  const PrinterRegister = (values) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }

    const newPrinter = {
      companyId: companyIdToUse,
      printerName: values.printerName,
      printerIpAddress: values.printerIpAddress,
      printerStatus: false,
      printerModel: values.printerModel,
      printerPort: values.printerPort,
      username:values.username,
      password:values.password,
      key:values.key,
      value:values.value,
      connectionProtocol:values.connectionProtocol,
      authentication: values.authentication,
      printerCode:values.printerCode

    }
    console.log(newPrinter)

    HTTPService.POST(`/Printer/registerPrinter`, newPrinter)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.replace('/admin/printer-management')
        }else if(response.status===450){
          toast.error(t(response.data));
        } 
        else {
          toast.error(response.data)
        }
      })
      .catch((error) => {
        console.error("Cihaz kaydı başarısız.");
      })
  }

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  const handleCancel = () => {
    window.location.replace('/admin/printer-management');
  };

  const handleChangeConnection = (value) => {
    setPrinterData(prevData => ({
      ...prevData,
      connectionProtocol: value,
    }));
  };

  const handleChangeAuthentication = (value) => {
    setPrinterData(prevData => ({
      ...prevData,
      authentication: value,
    }));
  };
  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('addPrinter')}</h3>
      <Form layout='vertical' onFinish={PrinterRegister}>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='selectedCompany'
            label={t('Select company')}
            rules={[{ required: true, message: t('please_select_company') }]}>
            <Select showSearch placeholder={t('company_name')} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item
          name='printerName'
          label={t('Printer Name')}
          rules={[{ required: true, message: t('pleaseInputPrinter'), whitespace: true }]}
        >
          <Input
            placeholder={t('Printer Name')}
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item
          name='printerModel'
          label={t('Printer Model')}
          rules={[{ required: true, message: t('Printer Model') }]}
        >
          <Select
            showSearch
            placeholder={t('Printer Model')}
          >
            <Select.Option value={0}>Zebra</Select.Option>
            <Select.Option value={1}>Sato</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='connectionProtocol'
          label={t('connection_protocol')}
          rules={[{ required: true, message: t('connection_protocol') }]}
        >
          <Select
            showSearch
            placeholder={t('connection_protocol')}
            onChange={handleChangeConnection}
          >
            <Select.Option value={0}>Direct Socket Connection</Select.Option>
            <Select.Option value={1}>Web Socket Server</Select.Option>
            <Select.Option value={2}>Web Server</Select.Option>
          </Select>
        </Form.Item>

        {printerData?.connectionProtocol === 2 && (
          <Form.Item
            label={t("authentication")}
            name={t("authentication")}
            rules={[
              {
                required: true,
                message: t('please_select_your_authentication_type'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t("authentication")}
              onChange={handleChangeAuthentication}
            >
              <Select.Option value={2}>NoAuth</Select.Option>
              <Select.Option value={0}>Basic</Select.Option>
              <Select.Option value={1}>Custom</Select.Option>
            </Select>
          </Form.Item>
        )}

        {printerData?.authentication === 0 && printerData?.connectionProtocol === 2 && (
          <>
            <Form.Item
              name="username"
              label={t("Username")}
              rules={[
                {
                  message: t('inputUsername'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t("Username")} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  username: e.target.value,
                }))
              } />
            </Form.Item>

            <Form.Item
              name="password"
              label={t("Password")}
              rules={[
                {
                  message: t('inputPassword'),
                  whitespace: true,
                },
              ]}
            >
              <Input.Password style={{ borderRadius: 0 }} placeholder={t("Password")} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  password: e.target.value,
                }))
              } />
            </Form.Item>
          </>
        )}
        {printerData?.authentication === 1 && printerData?.connectionProtocol === 2 && (
          <>
            <Form.Item
              name="key"
              label={t("key")}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_key'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t("key")} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  key: e.target.value,
                }))
              } />
            </Form.Item>

            <Form.Item
              name="value"
              label={t('value')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_value'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t('value')} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  value: e.target.value,
                }))
              } />
            </Form.Item>
          </>
        )}

        <Form.Item
          name='printerCode'
          label={t('Printer Code')}
          rules={[{ required: false, message: t('Printer Code'), whitespace: true }]}
        >
          <Input
            placeholder={t('Printer Code')}
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        <Form.Item
          name='printerIpAddress'
          label={t('Ip Address')}
          rules={[{ required: true, message: t('pleaseInputIpAdress'), whitespace: true }]}
        >
          <Input
            placeholder={t('Ip Address')}
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        {printerData?.connectionProtocol=== 0 &&(
             <Form.Item
             name='printerPort'
             label={t('Port')}
             rules={[{ required: true, message: t('pleaseInputPort'), whitespace: true }, { 
               pattern: /^[0-9]*$/, 
               message: 'Port must be a number'
             }]}
           >
             <Input
               placeholder={t('Port')}
               style={{ borderRadius: 0 }}
             />
           </Form.Item>
        )}

        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: 0 }}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddPrinter;
