import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Input, message, Modal, notification, Popconfirm, Row, Select, SelectProps, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { ICompany } from '../../../interfaces/ICompany';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import { UploadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { IDepartmentData } from '../../../interfaces/IDepartmentData';
import { ISupplierData } from '../../../interfaces/ISupplierData';
import { IBrandData } from '../../../interfaces/IBrandData';
import { ITypeData } from '../../../interfaces/ITypeData';
import { IInventoryStatusData } from '../../../interfaces/IInventoryStatusData';
import { IUserData } from '../../../interfaces/IUserData';
import { ICampusData } from '../../../interfaces/ICampusData';
import { ILocationData } from '../../../interfaces/ILocationData';
import { ICategoryData } from '../../../interfaces/ICategoryData';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import AddInventoryStatus from '../InventoryStatus/AddInventoryStatus';
import { IPageData } from '../../../interfaces/page-data';
import AddCampus from '../CampusManagement/AddCampus';
import AddLocation from '../LocationManagement/AddLocation';
import AddCategory from '../CategoryManagement/AddCategory';
import AddType from '../TypeManagement/AddType';
import AddDepartment from '../DepartmentManagement/AddDepartment';
import AddSupplier from '../SupplierManagement/AddSupplier';
import AddBrand from '../BrandManagement/AddBrand';
import { IItemData } from '../../../interfaces/IItemData';
import { useForm } from 'antd/lib/form/Form';

const AddInventory: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [companyData, setCompanyData] = useState<ICompany[]>([]);
    const [departmentData, setDepartmentData] = useState<IDepartmentData[]>([]);
    const [supplierData, setSupplierData] = useState<ISupplierData[]>([]);
    const [brandData, setBrandData] = useState<IBrandData[]>([]);
    const [typeData, setTypeData] = useState<ITypeData[]>([]);
    const [campusData, setCampusData] = useState<ICampusData[]>([]);
    const [categoryData, setCategoryData] = useState<ICategoryData[]>([]);
    const [locationData, setLocationData] = useState<ILocationData[]>([]);
    const [inventoryStatusData, setInventoryStatusData] = useState<IInventoryStatusData[]>([]);
    const [userData, setUserData] = useState([]); 
    const [filteredUserData, setFilteredUserData] = useState([]);
    const [itemData, setItemData] = useState<IItemData>({});
    const [selectedImages, setSelectedImages] = useState<File[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
    const [selectedCampus, setSelectedCampus] = useState<number | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined);
    const [selectedType, setSelectedType] = useState<number | undefined>(undefined);
    const [selectedResponsible, setSelectedResponsible] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleCampus, setIsModalVisibleCampus] = useState(false);
    const [isModalVisibleLocation, setIsModalVisibleLocation] = useState(false);
    const [isModalVisibleCategory, setIsModalVisibleCategory] = useState(false);
    const [isModalVisibleType, setIsModalVisibleType] = useState(false);
    const [isModalVisibleDepartment, setIsModalVisibleDepartment] = useState(false);
    const [isModalVisibleSupplier, setIsModalVisibleSupplier] = useState(false);
    const [isModalVisibleBrand, setIsModalVisibleBrand] = useState(false);

    //const formRef = useRef(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const loader = new StateLoader();
    const user: IAuthentication = loader.loadAuthState();

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (selectedCompany || user?.companyId) {
            fetchDepartment(selectedCompany || user.companyId);
            fetchCampusByCompany(selectedCompany || user.companyId);
            fetchCategoryByCompany(selectedCompany || user.companyId);
            fetchBrandByCompany(selectedCompany || user.companyId);
            fetchSupplier(selectedCompany || user.companyId);
            fetchInventoryStatus(selectedCompany || user.companyId);
            fetchUser(selectedCompany || user.companyId);
        } else {
            setDepartmentData([]);
            setSupplierData([]);
            setBrandData([]);
            setUserData([]);
        }
    }, [selectedCompany]);


    const columns: IAltisDataGridColumns[] = [
        {
          title: t('Responsible Name'),
          key: 'displayName',
          placeHolder: t('Responsible Name'),
          inputType:"input",
        },
        {
          title: t('Operations'),
          key: 'operations',
          placeHolder: t('Operations'),
          inputType:"operations",
          
            render: (order, record) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <Space size={50}>
              <Popconfirm
                            title={<div>
                             {t('responsibleToast')} <br />
                          </div>}
                            onConfirm={() => DeleteResponsible(record.userId)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  onClick={(e) => { e.stopPropagation(); }}
                >{t('delete')}</Button>
                </Popconfirm>
              </Space>
            </div>
          ),
        },
      ];

      const DeleteResponsible = (id: number) => {
        const newObj = { ...itemData };
    
        const responsible = userData.find(responsible => responsible.userId === id);
    
        if (Array.isArray(newObj.responsibleList)) {
            newObj.responsibleList = newObj.responsibleList.filter(row => row !== id);
        }
    
        setItemData(newObj);
    
        setFilteredUserData(prevData => prevData.filter(item => item.userId !== id));
    
        if (responsible) {
            toast.success(`"${responsible.displayName}"` +" "+ t('responsibleDeleteToast'));
        } else {
            toast.error(t('responsibleNotFound'));
        }
    };


    // const handleAddResponsible = () => {
    //     const selectedUserData = userData.find(app => app.userId === selectedResponsible);

    //     if (selectedUserData) {
    //         setFilteredUserData(prevData => [
    //             ...prevData,
    //             { userId: selectedUserData.userId, displayName: selectedUserData.displayName }
    //         ]);
    //     }
    // };

    const savedNewCampus=(campus:ICampusData) => {
        setCampusData(prevLocationData => [...prevLocationData, campus]);
        formRef.current.setFieldValue("campusId", campus.campusId);
        setLocationData([]);
        setSelectedCampus(campus.campusId)
        formRef.current.setFieldValue("locationId",null);
    }

    const savedNewLocation=(location:ILocationData) => {
        console.log(location)
        formRef.current.setFieldValue("locationId",location.locationId);
        setLocationData(prevLocationData => [...prevLocationData, location]);
    }

    const savedNewCategory=(category:ICategoryData) => {
        console.log(category)
        formRef.current.setFieldValue("categoryId",category.categoryId);
        setCategoryData(prevCategoryData => [...prevCategoryData, category]);
        setSelectedCategory(category.categoryId)
        formRef.current.setFieldValue("typeId",null);
        setTypeData([]);
    }
    
    const savedNewType=(type:ITypeData) => {
        console.log(type)
        formRef.current.setFieldValue("typeId",type.typeId);
        setSelectedCategory(type.categoryId)
        setTypeData(prevTypeData => [...prevTypeData, type]);
    }

    const savedNewDepartment=(department:IDepartmentData) => {
        console.log(department)
        console.log(department.departmentId)
        formRef.current.setFieldValue("departmentId",department.departmentId);
        setDepartmentData(prevDepartmentData => [...prevDepartmentData, department]);
    }

    const savedNewBrand=(brand:IBrandData) => {
        console.log(brand)
        formRef.current.setFieldValue("brandId",brand.brandId);
        setBrandData(prevBrandData => [...prevBrandData, brand]);
    }

    const savedNewSupplier=(supplier:ISupplierData) => {
        console.log(supplier)
        formRef.current.setFieldValue("supplierId",supplier.supplierId);
        setSupplierData(prevSupplierData => [...prevSupplierData, supplier]);
    }

    const savedNewStatus=(status:IInventoryStatusData) => {
        console.log(status)
        formRef.current.setFieldValue("inventoryStatusId",status.inventoryStatusId);
        setSupplierData(prevStatusData => [...prevStatusData, status]);
    }
    
    const handleAddResponsible = () => {
        const selectedUserData = userData.find(user => user.userId === selectedResponsible);
    
        if (selectedUserData && !filteredUserData.some(user => user.userId === selectedUserData.userId)) {
            setFilteredUserData(prevData => [
                ...prevData,
                { userId: selectedUserData.userId, displayName: selectedUserData.displayName }
            ]);
        }
    };
    
    const fetchCompanies = async () => {
        try {
            const result = await HTTPService.GETCompanyNames();
            setCompanyData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchCategoryByCompany = async (companyId: number) =>{
        try {
                const companyID =companyId ?? user.companyId;
                const result = await HTTPService.GETCategoryByCompany(companyID);
            setCategoryData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchBrandByCompany = async (companyId: number) => {
        try {
            const result = await HTTPService.GETBrandNames(companyId);
            setBrandData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchDepartment = async (companyId: number) => {
        try {
            const result = await HTTPService.GETDepartmentNames(companyId);
            setDepartmentData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchSupplier = async (companyId: number) => {
        try {
            const result = await HTTPService.GETSupplierNames(companyId);
            setSupplierData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchType = async (categoryId: string) => {
        try {
            const result = await HTTPService.GETTypeNamesByCategory(categoryId.toString());
            setTypeData(result);
            formRef.current.setFieldValue("typeId",null);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchInventoryStatus = async (companyId: number) => {
        try {
            const result = await HTTPService.GETInventoryStatusNames(companyId);
            setInventoryStatusData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchUser = async (companyId:number) => {
        try {
            const result = await HTTPService.GETUserNames(companyId);
            console.log(result);
            setUserData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchCampusByCompany = async (companyId: number) => {
        try {
            const result = await HTTPService.GETCampusByCompany(companyId);
            setCampusData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    const fetchAllLocationNameByCampus = async (campusId: string) => {
        try {
            const result = await HTTPService.GETAllLocationNameByCampus(campusId);
            setLocationData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error_message'));
        }
    };

    //  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //      const fileList = event.target.files;
    //      if (fileList) {
    //          const files = Array.from(fileList) as File[];
    //          setSelectedImages(prevImages => [...prevImages, ...files]);
    //      }
    //     };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (fileList) {
          const files = Array.from(fileList) as File[];
    
          // 100KB sınırı
          const maxSizeInBytes = 100 * 1024; 
    
          // Geçerli dosyaları filtrele
          const validFiles = files.filter(file => file.size <= maxSizeInBytes);
          const invalidFiles = files.filter(file => file.size > maxSizeInBytes);
    
           if (invalidFiles.length > 0) {
             notification.warning({
               message: t('fileSizeLimit'),
               description: t('fileSizeLimitDescription'),
             });
           }
    
          const totalImages = selectedImages.length + validFiles.length;
          if (selectedImages.length > 3 || totalImages>3 ) {
            notification.warning({
              message: t('limit_exceeded'),
              description: t('threeImage'),
            });
    
            setSelectedImages(prevImages => [
              ...prevImages,
              ...validFiles.slice(0, 3 - prevImages.length)
            ]);
          } else {
            setSelectedImages(prevImages => [...prevImages, ...validFiles]);
          }
        }
      };

    
    const handleImageRemove = (index: number) => {
        setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const handleSelectChange = (value) => {
        setSelectedResponsible(value);
    
        message.error({
          content: t('pressPlusIcon'),
          background:'red'
        });
    };
    

    const handleCategoryChange = (categoryId: number) => {
        fetchType(categoryId.toString())
        setSelectedCategory(categoryId)
    };

    // const handleTypeChange = (companyId: number) => {
    //     setSelectedType(companyId);
    // };
 

    const handleFormSubmit = async (values: any) => {
        const formData = new FormData();
    
        selectedImages.forEach((image, index) => {
            formData.append('files', image);
        });

        filteredUserData.forEach((userData, index) => {
            formData.append('responsibleUserList',userData.userId)
        })

        console.log(values)

        const referanceNo = values.referanceNo;
        const serialNo = values.serialNo;
        formData.append('inventoryTBL.barcode', "barcode"); // zorunlu alan oldugu için gönderilmektedir. readonly bir veridir.
        formData.append('inventoryTBL.companyId', user?.role === UserRole.AltisAdmin ? values.companyId : user?.companyId);
        formData.append('inventoryTBL.departmentId', values.departmentId?.toString());
        formData.append('inventoryTBL.supplierId', values.supplierId?.toString());
        formData.append('inventoryTBL.brandId', values.brandId?.toString());
        formData.append('inventoryTBL.inventoryStatusId', values.inventoryStatusId?.toString());
        formData.append('inventoryTBL.categoryId', values.categoryId?.toString());
        formData.append('inventoryTBL.campusId',values.campusId?.toString());
        formData.append('inventoryTBL.typeId', values.typeId.toString());
        formData.append('inventoryTBL.locationId', values.locationId?.toString());
        formData.append('inventoryTBL.locationName', values.locationName);
        if(referanceNo){
            formData.append('inventoryTBL.referanceNo', values.referanceNo);
        }
        formData.append('inventoryTBL.inventoryName', values.inventoryName);
        if(serialNo){
            formData.append('inventoryTBL.serialNo',values.serialNo);
        }
        if (values.debtUserId) {
        formData.append('inventoryTBL.debtUserId',values.debtUserId);
        }
        
        
        try {
            const response = await HTTPService.POST(`/Inventory/registerInventory`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 200) {
                toast.success(t(response.data))
                window.location.replace('/admin/inventory-management');
            }else if(response.status===450){
                toast.error(t(response.data))
            }
             else {
                toast.error(t('registration_failed'));
            }
        } catch (error) {
            console.error(t('registration_failed'));
        }
    };

    const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
        return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
    };
    const handleGridChange = () => {
        fetchUser(selectedCompany || user.companyId); 
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const showModalCampus = () => {
        setIsModalVisibleCampus(true);
    };

    const showModalLocation = () => {
        setIsModalVisibleLocation(true);
    };

    const showModalCategory = () => {
        setIsModalVisibleCategory(true);
    };

    const showModalDepartment = () => {
        setIsModalVisibleDepartment(true);
    };

    const showModalType = () => {
        setIsModalVisibleType(true);
    };

    const showModalSupplier = () => {
        setIsModalVisibleSupplier(true);
    };

    
    const showModalBrand = () => {
        setIsModalVisibleBrand(true);
    };

    const handleCancelCampus = () => {
        setIsModalVisibleCampus(false);
        fetchCampusByCompany(selectedCompany || user.companyId)
    };

    const handleCancelCategory = () => {
        setIsModalVisibleCategory(false);
        fetchCategoryByCompany(selectedCompany || user.companyId);
    };

    const handleCancelSupplier = () => {
        setIsModalVisibleSupplier(false);
        fetchSupplier(selectedCompany || user.companyId);
    };

    const handleCancelBrand = () => {
        setIsModalVisibleBrand(false);
        fetchBrandByCompany(selectedCompany || user.companyId);
    };
    
    const handleCancelDepartment = () => {
        setIsModalVisibleDepartment(false);
        fetchDepartment(selectedCompany || user.companyId)
    };
    
    const handleCancelStatus = () => {
        setIsModalVisible(false);
        fetchInventoryStatus(selectedCompany || user.companyId);
    };
    
    const selectedChangeCompany = (value) => 
    {
        setSelectedCompany(value);
        formRef.current.setFieldValue("campusId",null);
        formRef.current.setFieldValue("locationId",null);
        fetchCampusByCompany(value);
        formRef.current.setFieldValue("statusId",null);
        fetchInventoryStatus(value);
        formRef.current.setFieldValue("categoryId",null);
        fetchCategoryByCompany(value);
        formRef.current.setFieldValue("supplierId",null);
        fetchSupplier(value);
        formRef.current.setFieldValue("brandId",null);
        fetchBrandByCompany(value);
        formRef.current.setFieldValue("departmentId",null);
        fetchDepartment(value);
        formRef.current.setFieldValue("typeId",null);
        //fetchType(value);
        formRef.current.setFieldValue("responsibleList",null);
        formRef.current.setFieldValue("debtUserId",null);
        setItemData(prevData => ({ ...prevData, companyId: value }));
        setFilteredUserData([]);
    }
    const formRef = useRef(null);
    return (
        
        <Card  style={{ padding: '50px' }}>
            <h3>{t('register_item')}</h3>
            
            <Form ref={formRef} layout='vertical' onFinish={handleFormSubmit}>
                <Form.Item
                    name='images'
                    label={t('upload_image')}
                >
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        multiple
                    />
                    {selectedImages.length > 0 && (
                        <div style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', flexWrap: 'wrap' }}>
                            {selectedImages.map((image, index) => (
                                <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`preview ${index}`}
                                        style={{ maxWidth: '150px', maxHeight: '150px', borderRadius: '4px' }}
                                    />
                                    <Button
                                        icon={<DeleteOutlined />}
                                        size="small"
                                        style={{ position: 'absolute', bottom: '0', right: '0', margin: '5px' }}
                                        onClick={() => handleImageRemove(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <Button
                        icon={<UploadOutlined />}
                        onClick={() => fileInputRef.current?.click()}
                        style={{ marginBottom: '20px' }}
                    >
                        {t('Select Images')}
                    </Button>
                </Form.Item> 

                {user?.role === UserRole.AltisAdmin && (
                    <Form.Item
                        name='companyId'
                        label={t('Company Name')}
                        rules={[{ required: true, message: t('select_company') }]}
                    >
                        <Select
                            showSearch
                            placeholder={t('Company Name')}
                            //onChange={handleCompanyChange}
                            onChange={selectedChangeCompany}
                            filterOption={filterOption}
                        >
                            {companyData.map(company => (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

            <Form.Item
            name='campusId'
            label={
                <Tooltip title={t('select_campus_description')}>
                    {t('campus')}
                </Tooltip>
            }
            rules={[{ required: true, message: t('select_campus') }]}
            >
                <Select
                    placeholder={t('campus')}
                    onChange={(value) => (fetchAllLocationNameByCampus(value),setSelectedCampus(value))}
                    style={{ width: 'calc(100% - 50px)' }}  
                    dropdownStyle={{ minWidth: 300 }}
                    filterOption={filterOption}
                >
                    {campusData.map(row => (
                    <Select.Option key={row.campusId} value={row.campusId}>
                        {row.campusName}
                    </Select.Option>
                    ))}
                </Select>
                {/* <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={showModalCampus}
                />
                </Col>   */}
            </Form.Item>

                <Form.Item
                style={{ marginBottom: -5, marginTop: -8 }} 
                >
                <Row justify="end">
                    <Col>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={showModalCampus}
                        style={{ marginLeft: 8,marginTop:-100 }}  
                    />
                    </Col>
                </Row>
                </Form.Item>


            <Modal
                visible={isModalVisibleCampus}
                footer={null}
                onCancel={handleCancelCampus}
                width={790}
                style={{ top: 20 }}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div  style={{ flex: 1 }}>
                        <AddCampus cancelModal={handleCancelCampus} savedObj={savedNewCampus}
                        companyId={selectedCompany} campusId={itemData.locationTBL?.campusTBL?.campusId} campusName={itemData.locationTBL?.campusTBL?.campusName}
                    />
                </div>
            </Modal>
        
        <Form.Item
            name='locationId'
            label={
                <Tooltip title={t('select_location_description')}>
                    {t('location')}
                </Tooltip>
            }
            rules={[{ required: true, message: t('select_location') }]}
            >
                <Select
                    placeholder={t('location')}
                    onChange={() => setIsModalVisibleLocation(false)}
                    style={{ width: 'calc(100% - 50px)' }}  
                    dropdownStyle={{ minWidth: 300 }}
                    filterOption={filterOption}
                >
                    {locationData.map(row => (
                    <Select.Option key={row.locationId} value={row.locationId}>
                        {row.locationName}
                    </Select.Option>
                    ))}
                </Select>
                {/* <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={showModalCampus}
                />
                </Col> */}
            </Form.Item>

            <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }} 
            >
            <Row justify="end">
                <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModalLocation}
                    style={{ marginLeft: 8,marginTop:-100 }}
                />
                </Col>
            </Row>
            </Form.Item>
            <Modal
                visible={isModalVisibleLocation}
                footer={null}
                onCancel={() => setIsModalVisibleLocation(false)}
                width={790}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div style={{ flex: 1}}>
                    <AddLocation savedObj={savedNewLocation} cancelModal={() => setIsModalVisibleLocation(false)} companyId={selectedCompany} campusId={selectedCampus} campusTBL={itemData.locationTBL?.campusTBL} locationName={itemData.locationTBL?.campusTBL.campusName}/>
                </div>
            </Modal>

            <Form.Item
            name='departmentId'
            label={
                <Tooltip title={t('select_department_description')}>
                    {t('department_name')}
                </Tooltip>
            }
            rules={[{ required: true, message: t('department_name') }]}
            >
                <Select
                    placeholder={t('department_name')}
                    style={{ width: 'calc(100% - 50px)' }} 
                    dropdownStyle={{ minWidth: 300 }}
                    filterOption={filterOption}
                    //value={selectedDepartment}
                    //onChange={(value) => setSelectedDepartment(value)}
                >
                    {departmentData.map(row => (
                    <Select.Option key={row.departmentId} value={row.departmentId}>
                        {row.departmentName}
                    </Select.Option>
                    ))}
                </Select>
                {/* <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={showModalDepartment}
                />
                </Col> */}
            </Form.Item>

            <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }} 
            >
            <Row justify="end">
                <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModalDepartment}
                    style={{ marginLeft: 8,marginTop:-100 }}  // Responsive margin
                />
                </Col>
            </Row>
            </Form.Item>

            <Modal
                visible={isModalVisibleDepartment}
                footer={null}
                onCancel={handleCancelDepartment}
                width={790}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div style={{ flex: 1}}>
                    <AddDepartment cancelModal={handleCancelDepartment} savedObj={savedNewDepartment}
                       companyId={selectedCompany} departmentName={itemData.typeName} departmentId={itemData.departmentId}
                    />
                </div>
            </Modal>


        <Form.Item
            name='categoryId'
            label={
                <Tooltip title={t('select_category_description')}>
                    {t('item_group')}
                </Tooltip>
            }
            rules={[{ required: true, message: t('item_group') }]}
            >
                <Select
                    placeholder={t('item_group')}
                    onChange={handleCategoryChange}
                    style={{ width: 'calc(100% - 50px)' }} 
                    dropdownStyle={{ minWidth: 300 }}
                    filterOption={filterOption}

                >
                    {categoryData.map(row => (
                    <Select.Option key={row.categoryId} value={row.categoryId}>
                        {row.categoryName}
                    </Select.Option>
                    ))}
                </Select>
                {/* <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={showModalCategory}
                />
                </Col> */}
            </Form.Item>

            <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }} 
            >
            <Row justify="end">
                <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModalCategory}
                    style={{ marginLeft: 8,marginTop:-100 }}  // Responsive margin
                />
                </Col>
            </Row>
            </Form.Item>


            <Modal
                visible={isModalVisibleCategory}
                footer={null}
                onCancel={handleCancelCategory}
                width={790}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div style={{ flex: 1}}>
                    <AddCategory cancelModal={handleCancelCategory} savedObj={savedNewCategory}
                       companyId={selectedCompany} categoryName={itemData.categoryName} 
                    />
                </div>
            </Modal>


        <Form.Item
            name='typeId'
            label={
                <Tooltip title={t('select_type_description')}>
                    {t('item_type')}
                </Tooltip>
            }
            rules={[{ required: true, message: t('Please select type') }]}
            >
                <Select
                    placeholder={t('item_type')}
                    //onChange={handleTypeChange}
                    style={{ width: 'calc(100% - 50px)' }} 
                    dropdownStyle={{ minWidth: 300 }}
                    filterOption={filterOption}
                    value={selectedType}
                >
                    {typeData.map(row => (
                    <Select.Option key={row.typeId} value={row.typeId}>
                        {row.typeName}
                    </Select.Option>
                    ))}
                </Select>
                {/* <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={showModalType}
                />
                </Col> */}
            </Form.Item>

                <Form.Item
                style={{ marginBottom: 0, marginTop: -8 }} 
                >
                <Row justify="end">
                    <Col>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={showModalType}
                        style={{ marginLeft: 8,marginTop:-100 }}  // Responsive margin
                    />
                    </Col>
                </Row>
                </Form.Item>


            <Modal
                visible={isModalVisibleType}
                footer={null}
                onCancel={() => setIsModalVisibleType(false)}
                width={790}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div style={{ flex: 1}}>
                    <AddType cancelModal={() => setIsModalVisibleType(false)} savedObj={savedNewType}
                        companyId={selectedCompany} typeName={itemData.typeName} categoryId={selectedCategory}
                    />
                </div>
            </Modal>

            {console.log(selectedCategory)}


              <Form.Item
             name='supplierId'
            label={
                <Tooltip title={t('select_supplier_description')}>
                    {t('supplier_name')}
                </Tooltip>
            }
            rules={[{ required: true, message: t('supplier_name') }]}
            >
                <Select
                    placeholder={t('supplier_name')}
                    style={{ width: 'calc(100% - 50px)' }} 
                    dropdownStyle={{ minWidth: 300 }}
                    filterOption={filterOption}
                    //value={selectedSupplier}
                    //onChange={(value) => setSelectedSupplier(value)}
                >
                    {supplierData.map(row => (
                    <Select.Option key={row.supplierId} value={row.supplierId}>
                        {row.supplierName}
                    </Select.Option>
                    ))}
                </Select>
                {/* <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={showModalSupplier}
                />
                </Col> */}
            </Form.Item>

            <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }} 
            >
            <Row justify="end">
                <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModalSupplier}
                    style={{ marginLeft: 8,marginTop:-100 }}  
                />
                </Col>
            </Row>
            </Form.Item>


            <Modal
                visible={isModalVisibleSupplier}
                footer={null}
                onCancel={handleCancelSupplier}
                width={790}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div style={{ flex: 1}}>
                    <AddSupplier cancelModal={handleCancelSupplier} savedObj={savedNewSupplier}
                       companyId={itemData.companyId} supplierName={itemData.supplierName}
                    />
                </div>
            </Modal>
                
                <Form.Item
                name="inventoryStatusId"
                label={
                    <Tooltip title={t('select_status_description')}>
                        {t('status')}
                    </Tooltip>
                }
                rules={[{ required: true, message: t('pleaseStatus') }]}
            >
                        <Select
                            placeholder={t('status')}
                            style={{ width: 'calc(100% - 50px)' }} 
                            dropdownStyle={{ minWidth: 300 }}
                            filterOption={filterOption}
                            //value={selectedStatus}
                            //onChange={(value) => setSelectedStatus(value)}
                        >
                            {inventoryStatusData.map(row => (
                                <Select.Option
                                    key={row.inventoryStatusId}
                                    value={row.inventoryStatusId}
                                >
                                    {row.inventoryStatusName}
                                </Select.Option>
                            ))}
                        </Select>
                    {/* <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            style={{ marginLeft: '8px' }}
                            onClick={showModal}
                        />
                    </Col> */}
            </Form.Item>

            <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }} 
            >
            <Row justify="end">
                <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModal}
                    style={{ marginLeft: 8,marginTop:-100 }}  // Responsive margin
                />
                </Col>
            </Row>
            </Form.Item>

            <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancelStatus}
                width={790}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div  style={{ flex: 1}}>
                    <AddInventoryStatus savedObj={savedNewStatus}
                         companyId={selectedCompany} inventoryStatusName={itemData.inventoryStatusName} cancelModal={handleCancelStatus}
                    />
                </div>
            </Modal>

            
                <Form.Item
                    name='debtUserId'
                    label={
                        <Tooltip title={t('select_debit_description')}>
                            {t('Debit Name')}
                        </Tooltip>
                    }
                    rules={[{ required: false, message: t('debit_name') }]}
                >
                    <Select
                        placeholder={t('Debit Name')}
                        filterOption={filterOption}
                        //style={{width:"1300px"}}
                    >
                        <Select.Option key="none" value={null}>
                            {""}
                        </Select.Option>
                        {userData.map(row => (
                            <Select.Option key={row.userId} value={row.userId}>
                                {row.displayName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name='responsibleList'
                    label={
                        <Tooltip title={t('select_responsible_description')}>
                            {t('responsibleName')}
                        </Tooltip>
                    }
                    rules={[{ required: false, message: t('selectResponsible') }]}
                    style={{ marginBottom: 0 }}
                    >
                    <Row gutter={8} align="middle">
                        <Col flex="auto">
                        <Select
                            showSearch
                            placeholder={t('selectResponsible')}
                            onChange={handleSelectChange}
                            filterOption={filterOption}
                        >
                            {userData?.map((responsible) => (
                            <Select.Option key={responsible.userId} value={responsible.userId}>
                                {responsible.displayName}
                            </Select.Option>
                            ))}
                        </Select>
                        </Col>
                        <Col>
                        <Button type="primary" onClick={handleAddResponsible}>
                            {t('addResponsible')}
                        </Button>
                        </Col>
                    </Row>
                    <br></br>
                    <AltisDataGrid
                     data={filteredUserData}
                     total={filteredUserData.length}
                     columns={columns} 
                     onChange={handleGridChange}
                />
                <br></br>
                </Form.Item>
                <Form.Item
            name='brandId'
            label={
                <Tooltip title={t('select_brand_description')}>
                    {t('brand')}
                </Tooltip>
            }
            rules={[{ required: true, message: t('brand') }]}
            >
                <Select
                    placeholder={t('brand')}
                    style={{ width: 'calc(100% - 50px)' }} 
                    dropdownStyle={{ minWidth: 300 }}
                    filterOption={filterOption}
                    //value={selectedBrand}
                    //onChange={(value) => setSelectedBrand(value)}
                >
                    {brandData.map(row => (
                    <Select.Option key={row.brandId} value={row.brandId}>
                        {row.brandName}
                    </Select.Option>
                    ))}
                </Select>
                {/* <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginLeft: '8px' }}
                    onClick={showModalBrand}
                />
                </Col> */}
            </Form.Item>

            <Form.Item
            style={{ marginBottom: 0, marginTop: -8 }} 
            >
            <Row justify="end">
                <Col>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={showModalBrand}
                    style={{ marginLeft: 8,marginTop:-100 }}  // Responsive margin
                />
                </Col>
            </Row>
            </Form.Item>


            <Modal
                visible={isModalVisibleBrand}
                footer={null}
                onCancel={handleCancelBrand}
                width={790}
                bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
            >
                <div style={{ flex: 1}}>
                    <AddBrand cancelModal={handleCancelBrand} savedObj={savedNewBrand}
                       companyId={selectedCompany} brandName={itemData.brandName}
                    />
                </div>
            </Modal>
                <Form.Item
                    name='inventoryName'
                    label={t('item_name')}
                    rules={[{ required: true, message: t('input_item_name'), whitespace: true }]}
                >
                    <Input
                        placeholder={t('item_name')}
                    />
                </Form.Item>

                <Form.Item
                    name='serialNo'
                    label={t('serialNo')}
                    rules={[{ message: t('input_serialNo'), whitespace: true }]}
                >
                    <Input
                        placeholder={t('serialNo')}
                    />
                </Form.Item>

                <Form.Item
                    name='referanceNo'
                    label={t('Referance No')}
                    rules={[{message: t('input_destination'), whitespace: true }]}
                >
                    <Input
                        placeholder={t('Referance No')}
                    />
                </Form.Item>

                <Form.Item>
                    <Row gutter={[8, 8]} justify="end" align="middle">
                        <Col xs={12} sm={12} md={4}>
                            <Button block href="/admin/inventory-management" type="primary" style={{ borderRadius: 0 }}>
                                {t('cancel')}
                            </Button>
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                            <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                                {t('save')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default AddInventory;
