import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { useEffect, useState } from 'react';
import { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import { Button, Image, Modal, Popconfirm, Table } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { IPrintDetails, IPrintDetailsData } from '../../../interfaces/IPrintDetailsData';
import { useParams } from 'react-router-dom';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { t } from 'i18next';

const PrintsJobsDetailsAPI: React.FunctionComponent<IPageProps> = (props) => {
  const { printsJobsId } = useParams<any>();
  const { onSetPage } = props;
  const [printDetails, setPrintDetails] = useState<IPrintDetails>();
  const [isModalVisible, setIsModalVisible] = useState<number>(null);
  const [isModalImage, setIsModalImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  const handleCancel = () => {
    setIsModalVisible(null);
  };
  const handleImage = () => {
    setIsModalImage(false);
    setImageSrc('');
  };
  const pageData: IPageData = {
    title: t('prints_jobs_details'),
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    TagFilter();
  }, []);

  const TagFilter = (e?) => {
    console.log(e);
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      status: e?.status ?? null,
      printsJobsId: Number(printsJobsId),
      filterCreatedStartTime:
        e?.filterCreatedDate_start === null || e?.filterCreatedDate_start === ''
          ? null
          : new Date(Date.parse(e?.filterCreatedDate_start)),
      filterCreatedFinishTime:
        e?.filterCreatedDate_finish === null || e?.filterCreatedDate_finish === ''
          ? null
          : new Date(Date.parse(e?.filterCreatedDate_finish)),
      filterPrintedStartTime:
        e?.filterPrintedDate_start === null || e?.filterPrintedDate_start === ''
          ? null
          : new Date(Date.parse(e?.filterPrintedDate_start)),
      filterPrintedFinishTime:
        e?.filterPrintedDate_finish === null || e?.filterPrintedDate_finish === ''
          ? null
          : new Date(Date.parse(e?.filterPrintedDate_finish)),
    };
    console.log(body);
    HTTPService.POST(`/PrintsJobsDetails/PrintsJobsDetailsFilter`, body).then((response) => {
      if (!response.data) {
        return;
      }
      let tmp: IPrintDetails = response.data;
      console.log(tmp);
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setPrintDetails(tmp);
    });
  };
  const DeletePrintsJobsDetails = (id) => {
    HTTPService.DELETE(`/PrintsJobsDetails/DeletePrintJobsDetails?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
      } else {
        toast.error(t(response.data));
      }
    });
  };
  const ImagePriview = (id) => {
    HTTPService.POST(`/PrintsJobsDetails/Preview?printsJobsDetailsId=${id}&type=${"image"}`, '')
      .then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          console.log(tmp.value);
          setImageSrc(tmp.value);
          setIsModalImage(true);
        }
      })
      .catch((error) => {
        console.error(t('fetchImage'), error);
      });
  };
  const tagDataColumns: IAltisDataGridColumns[] = [
    {
      title: '',
      key: 'counter',
      placeHolder: t('counter'),
      inputType: 'input',
      width: 1000,
    },
    {
      title: t('status'),
      key: 'status',
      placeHolder: t('status'),
      inputType: 'boolean',
      width: 1000,
      render: (order: number, record: IPrintDetailsData) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          {record.status !== undefined ? (record.status ? 'Printed' : 'Not Printed') : 'Unknown'}
        </div>
      ),
    },
    {
      title: t('created_date'),
      key: 'createdDate',
      placeHolder: t('created_date'),
      inputType: 'date',
      width: 1000,
    },
    {
      title: t('printed_date'),
      key: 'printedDate',
      placeHolder: t('printed_date'),
      inputType: 'date',
      width: 1000,
    },

    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: 'operations',
      width: 1000,
      render: (order: number, record: IPrintDetailsData, index) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Button
            // shape='circle'
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              ImagePriview(record.printsJobsDetailsId);
            }}>
           {t('Image')}
          </Button>
          {user?.role === UserRole.AltisAdmin ? (
          <Popconfirm
            title={t('are_you_sure_printJob')}
            onConfirm={() => {
              DeletePrintsJobsDetails(record.printsJobsDetailsId);
            }}
            okText={t('yes')}
            cancelText={t('no')}>
            <Button
              size='small'
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              {t('delete')}
            </Button>
          </Popconfirm>
          ) : null}
          <Button
            // shape='circle'
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px' }}
            onClick={() => {
              setIsModalVisible(index);
            }}>
            {t('details')}
          </Button>
          {!record.status && (
            <Button
              // shape='circle'
              size='small'
              icon={<EyeOutlined />}
              style={{ width: '80px',display: user.role === UserRole.Sayman ? 'none' : 'block' }}
              onClick={() => {
                PrintData(record.printsJobsDetailsId)
              }}>
              {t('Reprint')}
            </Button>
          )}
        </div>
      ),
    },
  ];

  const PrintData = (id:number) => {
    console.log(id);
    var body = {
      printsJobsDetailsId: id
    };
    console.log(body);
    HTTPService.POST(`/PrintsJobsDetails/Reprint?printsJobsDetailsId=${id}`,"").then((response) => {
      console.log(response);
      if (response.status === 200) {
        toast.success(response.data);
        window.location.reload();
      } else {
        toast.error(response.data);
      }
      
    }).catch((response) => {
      
      console.error(response);
    })
  };
  const DetailsColumn = [
    {
      title: t('key'),
      key: 'Key',
      dataIndex: 'Key',
    },
    {
      title: t('value'),
      key: 'Value',
      dataIndex: 'Value',
    },
  ];

  function GetDetailData() {    
    
  if(printDetails?.data[isModalVisible]?.parameter == null) {
    return [];
  }
  const obj = JSON.parse(printDetails?.data[isModalVisible]?.parameter);

    if (obj != null) {
      let parameters = {};
      try {
        parameters = obj; // JSON stringini parse et
      } catch (e) {
        console.error('Invalid JSON in parameter:', obj);
        return [];
      }

      const data = Object.keys(parameters)
        .map((key) => {
          let value = parameters[key];
          if (value != null) {
            if (typeof value === 'boolean') {
              value = value ? 'Evet' : 'Hayır';
            }
            return {
              Key: key,
              Value: value,
            };
          }
          return null;
        })
        .filter((item) => item !== null); // Null item'ları filtrele

      return data;
    }
    return [];
  }

  return (
    <>
      <AltisDataGrid
        data={printDetails?.data}
        total={printDetails?.count}
        columns={tagDataColumns}
        onChange={TagFilter}
      />
      
      <Modal
        title={<span style={{ color: 'red' }}>Details</span>}
        visible={isModalVisible != null}
        bodyStyle={{ width: 500, height: 500 }}
        footer={null}
        onCancel={handleCancel}>
        <Table
          pagination={{
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          columns={DetailsColumn}
          dataSource={GetDetailData()}
        />
      </Modal>
      <Modal title={t('Image preview')} visible={isModalImage} onCancel={handleImage} footer={null}>
        {/* {imageSrc && <Image src={imageSrc} alt="Preview" style={{ width: '100%' }} />} */}
        {imageSrc?.labels?.map((labelInfo, index) => (
          <Image
            key={index}
            src={`data:image/png;base64,${labelInfo.imageBase64}`}
            style={{ width: 'auto', height: 'auto' }}
            alt='Preview'
          />
        ))}
      </Modal>
    </>
  );
};
export default PrintsJobsDetailsAPI;
