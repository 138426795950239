import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Avatar, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { CloudFilled, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flagkit';
import './Actions.scss';
import { removeUser } from '../../../redux/user/actions';

const Actions: React.FunctionComponent<any> = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [isConnected, setIsConnected] = useState(false);
    const [user, setUser] = useState("");

    const loader = new StateLoader();

    useEffect(() => {
        const userInfo: IAuthentication = loader.loadAuthState();
        console.log(userInfo)
        setUser(`${userInfo?.firstname} ${userInfo?.lastname} - ${UserRole[userInfo?.role]}`);

        
    }, []);

    const logout = () => {
        dispatch(removeUser());
        loader.removeAuthLoader();
        window.location.replace('/public/sign-in/');
    };

    const resetPassword = () => {
        window.location.replace('/admin/reset-password');
    };

    const changeEmail = () => {
        window.location.replace('/admin/change-email');
    };

    const changeLanguage = (lng: string) => {
        if (i18n.changeLanguage) {
            i18n.changeLanguage(lng);
            loader.setLanguage(lng);
        } else {
            console.error('i18n.changeLanguage is not a function');
        }
    };

    const languageMenu = (
        <Menu>
            <Menu.Item key='1' onClick={() => changeLanguage('tr')}>
                <Flag country="TR" style={{ marginRight: '8px' }} />
                {t('turkish')}
            </Menu.Item>
            <Menu.Item key='2' onClick={() => changeLanguage('en')}>
                <Flag country="US" style={{ marginRight: '8px' }} />
                {t('english')}
            </Menu.Item>
        </Menu>
    );

    const accountMenu = (
        <Menu style={{ minWidth: '200px' }}>
            <Menu.SubMenu key='profile' title={t('profile')} style={{ marginLeft: 7 }}>
                <Menu.Item key='reset-password' onClick={resetPassword}>
                    {t('reset_password')}
                </Menu.Item>
                <Menu.Item key='change-email' onClick={changeEmail}>
                    {t('change_email')}
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key='logout'>
                <span className='d-flex justify-content-between align-items-center'>
                    <a className='d-flex w-100' onClick={logout}>
                        {t('log_out')}
                    </a>
                    <span className='d-flex align-items-center icofont-logout' />
                </span>
            </Menu.Item>
        </Menu>
    );

    // Determine the button text based on the current language
    const buttonText = i18n.language === 'tr' ? <Flag country="TR" /> : <Flag country="US" />;

    return (
        <div className='actions'>
            <div>{user}</div>
            <Dropdown overlay={accountMenu} trigger={['click']}>
                <div className='item'>
                    <UserOutlined className='mr-1' src={null} />
                    <span className='icofont-simple-down' />
                </div>
            </Dropdown>
            {/* <CloudFilled 
                style={{ color: isConnected ? 'green' : 'red', marginLeft: '10px', fontSize: '30px' }} 
            /> */}
            <Dropdown overlay={languageMenu} trigger={['click']}>
                <Button className='language-selector'>
                    {buttonText} <span className='icofont-simple-down' />
                </Button>
            </Dropdown>
        </div>
    );
};

export default Actions;
