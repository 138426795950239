import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { IBrandData } from "../../../interfaces/IBrandData";
import { useTranslation } from 'react-i18next'; 
import { ICampusData } from "../../../interfaces/ICampusData";
import { IPageProps } from "../../../interfaces/page-data";

const UpdateCampus : React.FunctionComponent<IPageProps> = () => {
  const { campusId } = useParams<any>();
  const [campusData, setCampusData] = useState<ICampusData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const { t } = useTranslation(); 

  useEffect(() => {
    fetchData();
    if (campusId) {
      getCampusData(campusId);
    }
  }, [campusId]);

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  };

  const getCampusData = (campusId: number) => {
    HTTPService.GET(`/Campus/ById?campusId=${campusId}`)
      .then((res) => {
        if (res.status === 200 && res.data) {
          console.log(res.data)
          setCampusData(res.data);
        } else {
          toast.error(res.error?.message || t('error_fetching_data'));
        }
      })
      .catch((error) => {
        toast.error(t('error_fetching_data'));
        console.error("Error fetching brand data:", error);
      });
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };


  const updateCampus = (formData) => {
    const updatedCampus = {
      campusId: campusId,
      campusName: formData.campusName,
      barcode:formData.barcode,
      companyId: formData.companyId || userInfo.companyId,
    };

    HTTPService.PUT(`/Campus/updateCampus`, updatedCampus)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('campus_updated_successfully'));
           window.location.replace("/admin/campus-management");
        }
        else if(res.status ===450){
          toast.error(t(res.data))
        }
        else {
          toast.error(t('campus_update_failed'));
        }
        
        
      })
      
      .catch(() => {
        toast.error(t('campus_update_failed'));
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/campus-management/");
  };

  return (
    <Form
      layout="vertical"
      onFinish={updateCampus}
      fields={[
        { name: ["campusName"], value: campusData?.campusName },
        { name: ["barcode"], value: campusData?.barcode},
        { name: ["companyId"], value: campusData?.companyId },
      ]}
    >
      {console.log(campusData)}
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_campus')}</h3>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="campusName"
              label={t("campus")}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_campus'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t('campus')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name='barcode'
          label={t('barcode')}
        >
          <Input
            placeholder={t('barcode')}
            value={campusData?.barcode}
            readOnly
          />
        </Form.Item>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateCampus
