import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { toast } from "react-hot-toast";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { t } from "i18next";
import { IPageProps } from "../../../interfaces/page-data";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import HTTPService from "../../../utils/makerequest";
import { ICompany } from "../../../interfaces/ICompany";
import { IPrinterManagement } from "../../../interfaces/IPrinterManagement";


const UpdatePrinter: React.FunctionComponent<IPageProps> = () => {
  const { printerId } = useParams<any>();
  const [printerData, setPrinterData] = useState<IPrinterManagement>();
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    GetPrinterData(printerId)
    fetchData();
  }, [printerId]);


  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    console.log(result);
    setCompanyData(result);
  }
  const update = (e) => {
    console.log(e)
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = e.companyId || selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const updatedPrinter = {
      companyId: companyIdToUse,
      printerName: e.printerName,
      printerIpAddress: e.printerIpAddress,
      printerStatus: false,
      printerModel: e.printerModel,
      printerPort: e.printerPort,
      printerId: printerId,
      username: e.username,
      password: e.password,
      key: e.key,
      value: e.value,
      connectionProtocol: e.connectionProtocol,
      authentication: e.authentication,
      printerCode:e.printerCode
    };
    console.log(updatedPrinter);
    HTTPService.PUT(`/Printer/updatePrinter`, updatedPrinter)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(t(res.data))
          window.location.replace('/admin/printer-management')

        }else if(res.status===450){
          toast.error(t(res.data))
        }
        else {
          toast.error(res.data);
        }
      })
      .catch(error => {
        toast.error('There was an error!', error);
      });

  }

  const GetPrinterData = async (printerId: number) => {

    const res = await HTTPService.GET(`/Printer/ById?printerId=${printerId}`);
    if (res.status === 200 && res.data) {
      console.log(res.data)
      setPrinterData(res.data)
      setSelectedCompany(res.data.companyId)
    }
    else {
      toast.error(res.error?.message || t('error_fetching_data'));
    }

  };
  const handleCancel = () => {
    window.location.replace('/admin/printer-management');
  };


  const handleChangeConnection = (value) => {
    setPrinterData(prevData => ({
      ...prevData,
      connectionProtocol: value,
    }));
  };

  const handleChangeAuthentication = (value) => {
    setPrinterData(prevData => ({
      ...prevData,
      authentication: value,
    }));
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('update printer')}</h3>{
      }
      <Form layout='vertical' onFinish={update}
        fields={[
          {
            name: ["printerId"],
            value: printerData?.printerId
          },
          {
            name: ["printerName"],
            value: printerData?.printerName
          },
          {
            name: ["companyId"],
            value: printerData?.companyId
          },
          {
            name: ["printerModel"],
            value: printerData?.printerModel
          },
          {
            name: ["printerIpAddress"],
            value: printerData?.printerIpAddress
          },
          {
            name: ["printerPort"],
            value: printerData?.printerPort
          },
          {
            name: ["printerCode"],
            value: printerData?.printerCode
          },
          {
            name: ['username'],
            value: printerData?.username,
          },
          {
            name: ['password'],
            value: printerData?.password,
          },
          {
            name: ['connectionProtocol'],
            value: printerData?.connectionProtocol,
          },
          {
            name: ['authentication'],
            value: printerData?.authentication,
          },
          {
            name: ['key'],
            value: printerData?.key,
          },
          {
            name: ['value'],
            value: printerData?.value,
          },
        ]}
      >

        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            //name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('select_company'),
              },
            ]}
          >

            <Select
              showSearch placeholder={t('Select company')}
              value={selectedCompany}
              style={{ marginBottom: '20px' }}
              filterOption={filterOption}
              onChange={(value)=>{setSelectedCompany(value);}}
            >
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>

          </Form.Item>
        ) : null}
        <Form.Item
          name='printerName'
          label={t('Printer Name')}
          rules={[{ required: true, message: t('pleaseInputPrinter'), whitespace: true }]}
        >
          <Input
            placeholder={t('Printer Name')}
            style={{ borderRadius: 0 }}
            value={printerData?.printerName}
            onChange={(e) =>
              setPrinterData((prevData) => ({
                ...prevData,
                printerName: e.target.value,
              }))
            }
          />
        </Form.Item>
        <Form.Item
          name='printerModel'
          label={t('Printer Model')}
          rules={[{ required: true, message: 'Printer Model' }]}
        >
          <Select
            showSearch
            placeholder={t('Printer Model')}
            value={printerData?.printerModel}
            onChange={(value) =>
              setPrinterData((prevData) => ({
                ...prevData,
                printerModel: value,
              }))
            }
          >
            <Select.Option value={0}>Zebra</Select.Option>
            <Select.Option value={1}>Sato</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='connectionProtocol'
          label={t('connection_protocol')}
          rules={[{ required: true, message: 'Connection Protocol' }]}
        >
          <Select
            showSearch
            placeholder={t('connection_protocol')}
            onChange={handleChangeConnection}
            filterOption={filterOption}
          >
            <Select.Option value={0}>Direct Socket Connection</Select.Option>
            <Select.Option value={1}>Web Socket Server</Select.Option>
            <Select.Option value={2}>Web Server</Select.Option>
          </Select>
        </Form.Item>

        {printerData?.connectionProtocol === 2 && (
          <Form.Item
            label={t("authentication")}
            name="authentication"
            rules={[
              {
                required: true,
                message: t('please_select_your_authentication_type'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t("authentication")}
              onChange={handleChangeAuthentication}
              filterOption={filterOption}
            >
              <Select.Option value={2}>NoAuth</Select.Option>
              <Select.Option value={0}>Basic</Select.Option>
              <Select.Option value={1}>Custom</Select.Option>
            </Select>
          </Form.Item>
        )}

        {printerData?.authentication === 0 && printerData?.connectionProtocol === 2 && (
          <>
            <Form.Item
              name="username"
              label={t("username")}
              rules={[
                {
                  message: t('please_input_your_username'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t('please_input_your_username')} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  username: e.target.value,
                }))
              } />
            </Form.Item>

            <Form.Item
              name="password"
              label={t("password")}
              rules={[
                {
                  message: t('please_input_your_password'),
                  whitespace: true,
                },
              ]}
            >
              <Input.Password style={{ borderRadius: 0 }} placeholder={t("password")} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  password: e.target.value,
                }))
              } />
            </Form.Item>
          </>
        )}
        {printerData?.authentication === 1 && printerData?.connectionProtocol === 2 && (
          <>
            <Form.Item
              name="key"
              label={t('key')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_key'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t('key')} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  key: e.target.value,
                }))
              } />
            </Form.Item>

            <Form.Item
              name="value"
              label={t('value')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_value'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t('value')} onChange={(e) =>
                setPrinterData((prevData) => ({
                  ...prevData,
                  value: e.target.value,
                }))
              } />
            </Form.Item>
          </>
        )}

        <Form.Item
          name='printerCode'
          label={t('Printer Code')}
          rules={[{ required: false, message: t('Printer Code'), whitespace: true }]}
        >
          <Input
            placeholder={t('Printer Code')}
            style={{ borderRadius: 0 }}
            onChange={(e) =>
              setPrinterData((prevData) => ({
                ...prevData,
                printerCode: e.target.value,
              }))}
          />
        </Form.Item>

        <Form.Item
          name='printerIpAddress'
          label={t('Ip Address')}
          rules={[{ required: true, message: t('pleaseInputIpAdress'), whitespace: true }]}
        >
          <Input
            placeholder={t('Ip Address')}
            style={{ borderRadius: 0 }}
            value={printerData?.printerIpAddress}
            onChange={(e) =>
              setPrinterData((prevData) => ({
                ...prevData,
                printerIpAddress: e.target.value,
              }))
            }
          />
        </Form.Item>
        
        {(printerData?.connectionProtocol===0) && (
           <Form.Item
           name='printerPort'
           label={t('Port')}
           rules={[
             { required: true, message: t('pleaseInputPort') },
             { pattern: /^[0-9]+$/, message: t('pleaseInputPort') }  // Minimum bir rakam olmalı
           ]}
         >
           <Input
             placeholder={t('Port')}
             style={{ borderRadius: 0 }}
             value={printerData?.printerPort || ''}  // Boş bir string döndürmelidir
             onChange={(e) => {
               const value = parseInt(e.target.value, 10);
               setPrinterData(prevData => ({
                 ...prevData,
                 printerPort: isNaN(value) ? 0 : value,  // NaN değerini 0 olarak ayarla
               }));
             }}
           />
         </Form.Item>
                
        )}
        
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
              {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
}
export default UpdatePrinter;