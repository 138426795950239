import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Row } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, PlayCircleOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import HTTPService from '../../utils/makerequest';
import './../../assets/sass/components/Dashboard.scss';
import { IPageData } from '../../interfaces/page-data';
import { t } from 'i18next';

const Dashboard: React.FunctionComponent<any> = props => {
  const { onSetPage } = props;   
  const [dashboardInfo, setDashboardInfo] = useState<boolean>(false);

  const pageData: IPageData = {
    title: t('dashboard'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    getWidgets();
  }, []);

  const signalRReceive = (e) => {
    getWidgets();
  };

  const getWidgets = () => {
    HTTPService.GET('/Dashboard/status').then(async (response) => {
      if (!response.data) {
        return;
      } 
      let tmp = await response.data;
      console.log(tmp);
      setDashboardInfo(tmp.edgeStatus);
    });
  };

  return (
    <>
      <Alert
        className='mb-4'
        message={t('Welcome to The Tag And Trace Assets System')}
        type='success'
        showIcon
      />
{/*       
      <Card>
        <Row className='row'>
          <div className='col-md-4' style={{marginLeft:30,marginTop:20}}>
            <Card
              className='animated zoomIn delay-04s'
              style={{padding:"20px",backgroundColor: dashboardInfo ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)' ,height:"150px"}}
            >
              <div className='row align-items-center pt-2'>
                <div className='col col-auto'>
                  {dashboardInfo ? (
                    <CheckCircleOutlined style={{ color: 'green', fontSize: 26 }} />
                  ) : (
                    <CloseCircleOutlined style={{ color: 'darkred', fontSize: 26 ,marginLeft:"10px"}} />
                  )}
                </div>
                
                <div className='col col-4' >
                  <h6 className='mt-0 mb-1 nowrap' style={{ color: 'black' }}>
                    {"Edge"} 
                  </h6>
                  <div
                    className='count'
                    style={{
                      fontSize: 16,
                      color: !dashboardInfo ? 'green' : 'darkred',
                      fontWeight: 'bolder',
                      marginBottom: 10,
                    }}
                  >
                    {dashboardInfo ? 'Active' : 'Inactive'}
                  </div>
                </div>
                
                <div className='col' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    style={{
                      width: '50%',
                      marginBottom: 2,
                      marginRight: 2,
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                    shape='round'
                    icon={<PlayCircleOutlined />}
                    size='small'
                    id={"Edge"+'Start'}
                  >
                    Start
                  </Button>
                  
                  <Button
                    style={{
                      width: '50%',
                      marginBottom: 2,
                      marginRight: 2,
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                    shape='round'
                    icon={<StopOutlined />}
                    size='small'
                    id={"Edge"+'Stop'}
                  >
                    Stop
                  </Button>
                  
                  <Button
                    style={{
                      width: '50%',
                      marginBottom: 2,
                      marginRight: 2,
                      textAlign: 'left',
                      fontSize: 14,
                    }}
                    shape='round'
                    icon={<SyncOutlined />}
                    size='small'
                    id={"Edge"+'Restart'}
                  >
                    Restart
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Row>
      </Card> */}
    </>
  );
};

export default Dashboard;
