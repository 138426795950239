import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid, { IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IApplicationData } from "../../../interfaces/IApplicationData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; // Ekleyin
import { IDepartmentData } from "../../../interfaces/IDepartmentData";
import i18n from "../../../i18n";

export interface IDepartment {
  count?: number;
  data?: IDepartmentData[];
}

const DepartmentManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const [departmentData, setDepartmentData] = useState<IDepartment>({});
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: t('department_management'), 
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    DepartmentFilter();
  }, [i18n.language]);


  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: 'Tcp Client' },
    { id: 1, name: 'Tcp Server' },
    { id: 2, name: 'Web Socket Client' },
    { id: 3, name: 'Web Service' },
    { id: 4, name: 'Serial Port' },
    { id: 5, name: 'Web Socket Server' }
  ];

  const columns: IAltisDataGridColumns[] = [
    { title: t('company_name'), key: 'companyName', placeHolder: t('company_name'), inputType: "input" },
    { title: t('department_name'), key: 'departmentName', placeHolder: t('department_name'), inputType: "input" },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: "operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
            <Popconfirm
              title={<div>{t('are_you_sure')} <br /></div>}
              onConfirm={() => DeleteDepartment(record.departmentId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button
                size='small'
                icon={<DeleteOutlined />}
                onClick={(e) => { e.stopPropagation(); }}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
              >
                {t('delete')}
              </Button>
            </Popconfirm>

            <Button
              size='small'
              icon={<EditOutlined />}
              onClick={() => window.location.replace('/admin/update-department/' + record.departmentId)}
              style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            >
              {t('update')}
            </Button>
          </Space>
        </div>
      ),
    }
  ];

  const DepartmentFilter = (e?) => {
    const body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      departmentName: e?.departmentName,
      companyName: e?.companyName,
    };

    HTTPService.POST(`/Department/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        setDepartmentData(response.data);
      } else {
        toast.error(response.data);
      }
      const tmp: IDepartment = response.data;
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setDepartmentData(tmp);
    });
  };

  const DeleteDepartment = (Id) => {
    HTTPService.DELETE(`/Department/deleteDepartment?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else {
          toast.error(t(response.data));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px 30px 30px 5px', marginBottom: 20, display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-department')}
          >
            {t('add_new_department')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-container">
            <AltisDataGrid data={departmentData.data} total={departmentData.count} columns={columns} onChange={DepartmentFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentManagement;
