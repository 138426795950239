import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next';
import { ILocationData } from "../../../interfaces/ILocationData";
import { ICampusData } from "../../../interfaces/ICampusData";
import { IPageProps } from "../../../interfaces/page-data";

const UpdateLocation: React.FunctionComponent<IPageProps> = () => {
  const { locationId } = useParams<any>();
  const [locationData, setLocationData] = useState<ILocationData>();
  const [campusData, setCampusData] = useState<ICampusData[]>([]);
  const [companyData, setCompanyData] = useState<ICompanyData[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
  const [selectedCampus, setSelectedCampus] = useState<number | undefined>(undefined);
  const [form] = Form.useForm(); // Form instance
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const { t } = useTranslation();

  useEffect(() => {
    fetchCompanyData();
    if (locationId) {
      getLocationData(locationId);
    }
  }, [locationId]);

  // useEffect(() => {
  //   if (locationData) {
  //     form.setFieldsValue({
  //       locationName: locationData.locationName,
  //       companyId: locationData.campusTBL.companyId,
  //       campusId: locationData.campusTBL.campusId,
  //     });
  //     setSelectedCompany(locationData.campusTBL.companyId);
  //     fetchCampusesByCompany(locationData.campusTBL.companyId);
  //   }
  // }, [locationData, form]);

  useEffect(() => {
    if (selectedCompany) {
      fetchCampusesByCompany(selectedCompany);
    } else {
      setCampusData([]);
    }
  }, [selectedCompany]);

  const fetchCompanyData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const getLocationData = async (locationId: number) => {
    try {
      const res = await HTTPService.GET(`/Location/ById?locationId=${locationId}`);
      if (res.status === 200 && res.data) {
        console.log(res.data)
        setLocationData(res.data);
        setSelectedCampus(res.data.campusId)
        setSelectedCompany(res.data.campusTBL.companyId);
      } else {
        toast.error(res.error?.message || t('error_fetching_data'));
      }
    } catch (error) {
      toast.error(t('error_fetching_data'));
      console.error("Error fetching location data:", error);
    }
  };

  const fetchCampusesByCompany = async (companyId: number) => {
    try {
      const result = await HTTPService.GETCampusByCompany(companyId);
      setCampusData(result);
    } catch (error) {
      console.error("Error fetching campuses by company:", error);
      setCampusData([]);
    }
  };

  const updateLocation = (formData) => {
    const updatedLocation = {
      locationId: locationId,
      locationName: formData.locationName,
      companyId: selectedCompany|| userInfo.companyId,
      campusId: selectedCampus
    };

    HTTPService.PUT(`/Location/updateLocation`, updatedLocation)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t(res.data));
          window.location.replace("/admin/location-management");
        } else if(res.status===450){
          toast.error(t(res.data))
        } 
         else {
          toast.error(res.data);
        }
      })
      .catch(() => {
        toast.error(t('location_update_failed'));
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/location-management/");
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={updateLocation}
      fields={[
        { name: ["locationName"], value:locationData?.locationName,},
        { name: ["campusId"], value: locationData?.campusTBL.campusId },
        { name: ["companyId"], value:locationData?.campusTBL.companyId },
        { name: ["barcode"], value:locationData?.barcode },
      ]}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_location')}</h3>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            //name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t('company_name')}
              style={{ marginBottom: '6px' }}
              onChange={(value) => {
                setSelectedCompany(value);
                setSelectedCampus(undefined)
                form.setFieldsValue({ companyId: value });
              }}
              filterOption={filterOption}
              value={selectedCompany}
            >
              {companyData.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        <Form.Item
          //name='campusId'
          label={t('campus')}
          rules={[{ required: true, message: t('select_campus') }]}
        >
          <Select placeholder={t('campus')}    
            value={selectedCampus}
              onChange={(value) => {
                setSelectedCampus(value)
              }}>
            {campusData.map(row => (
              <Select.Option key={row.campusId} value={row.campusId}>
                {row.campusName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="locationName"
              label={t('location')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_location'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0 }} placeholder={t('location')}  value={locationData?.locationName}
            onChange={(e) =>
            setLocationData((prevData) => ({
              ...prevData,
              locationName: e.target.value,
            }))
          } />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name='barcode'
          label={t('barcode')}
        >
          <Input
            placeholder={t('barcode')}
            value={locationData?.barcode}
            readOnly
          />
        </Form.Item>


        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: 0 }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                {t('Update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateLocation;
