
import { Button, Popconfirm, Tooltip } from 'antd';
import HTTPService from '../../../utils/makerequest';
import { IPageData } from '../../../interfaces/page-data';
import { useEffect, useState } from 'react';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import toast from 'react-hot-toast';
import { IAltisDataGridColumns, IDropdownItem } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import '../PrinterManagement/Device.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IInventory, IItemData } from '../../../interfaces/IItemData';

const DetailsCounting: React.FunctionComponent<any> = (props) => {
  const { onSetPage } = props;
  const { countingJobId } = useParams<any>();
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [inventoryData, setInventoryData] = useState<IInventory>();

  const pageData: IPageData = {
    title: 'Details Counting',
    loaded: true,
  };

  useEffect(() => {
    onSetPage(pageData);
    CountingDetails();
  }, []);


  const columns: IAltisDataGridColumns[] = [
    { title: t('Company Name'), key: 'companyName', placeHolder: t('Company Name'), inputType: 'input' },
    { title: t('item-name'), key: 'inventoryName', placeHolder: t('Item Name'), inputType: 'input' },
    { title: t('barcode'), key: 'barcode', placeHolder: t('Barcode'), inputType: 'input' },
    { title: t('item_type'), key: 'typeName', placeHolder: t('Item Type'), inputType: 'input', width: 500 },
    { title: t('item_group'), key: 'categoryName', placeHolder: t('Item Group'), inputType: 'input', width: 500 },
    { title: t('location'), key: 'locationName', placeHolder: t('Location'), inputType: 'input' },
    { title: t('inventory_status_name'), key: 'inventoryStatusName', placeHolder: t('Inventory Status Name'), inputType: 'input' },
  ];

  const CountingDetails = (e?) => {
    const body = {
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      barcode: e?.barcode,
      departmentName: e?.departmentName,
      inventoryStatusName: e?.inventoryStatusName,
      typeName: e?.typeName,
      debtFirstName: e?.debtFirstName,
      debtLastName:e?.debtLastName,
      companyId: e?.companyId,
      companyName: e?.companyName,
      itemName:e?.itemName,
      itemGroup:e?.itemGroup,
      locationName:e?.locationName,
      categoryName:e?.categoryName
    };
    const Countingdata = {
        filters: body,
        countingJobId: countingJobId
    }

    HTTPService.POST(`/CountingJob/CountingDetails`, Countingdata).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        console.log(response.data)
        setInventoryData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

 

  return (
    <>
      
      <div className='row'>
        <div className='col-md-12'>
          <AltisDataGrid
            data={inventoryData?.data}
            total={inventoryData?.count}
            columns={columns}
            onChange={CountingDetails}
          />
        </div>
      </div>
    </>
  );
};

export default DetailsCounting;
